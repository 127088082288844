import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicineVisitsReturnPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Visite Rientro'

  const description =
    'La visita di rientro è quella cui sottoporre il lavoratore prima della ripresa del lavoro, dopo un’assenza per motivi di salute superiore ai sessanta giorni.<br><br> ING GROUP, attraverso la sua fitta rete di medici del lavoro e centri medici sparsi su tutto il territorio nazionale, è in grado di gestire in tempi brevissimi visite di rientro anche singole.'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
